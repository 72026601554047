.eva-btn {
  background-color: #c6c6c6;
  border: none;
  border-radius: 43px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 67%;
  top: 1.5%;
  height: 86px;
  width: 205px;
  cursor: pointer;
}

.eva-btn:disabled {
  cursor: not-allowed;
}

.eva-btn-image {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  margin-right: 15px;
  position: absolute;
  left: 67%;
  top: 1.5%;
}

.eva-btn-text {
  color: #ffffff;
  font-family: "Segoe UI-Bold", Helvetica;
  font-size: 40px;
  font-weight: 700;
  margin-left: 95px;
}

.moveable {
  cursor: move;
}

.sliding-div {
  position: absolute;
  top: 0;
  right: -100%;
  width: 45%;
  height: 100%;
  background-color: transparent;
  transition: right 0.3s ease-in-out;
}

.sliding-div.visible {
  right: 0;
}

