body {
  margin: 0;
  overflow: hidden;
  font-size: 30px;
}

.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.sidebar {
  width: 2040px;
  height: 100vh;
  background-color: black;
  position: absolute;
  top: 0;
  z-index: 9999;
}

.buttons {
  display: flex;
  gap: 30px;
  position: absolute;
  top: 1%;
  left: 4730px;
  z-index: 9999;
}

.toggle-button {
  display: flex;
  gap: 30px;
  position: absolute;
  top: 1%;
  /* left: 4600px; */
  z-index: 9999;
  height: 86px;
  width: 100vw;
  background-color: transparent;
  border: none;
  right: 800px;
}

