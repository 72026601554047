.eva-dashboard {
  display: flex;
  justify-content: space-between;
  border: 1px solid;
  background-color: #000000;
  padding: 24px;
}

.eva-dashboard .overlap-group {
  padding-left: 48px;
  display: flex;
  align-items: center;
  gap: 60px;
}

.eva-dashboard .text-wrapper {
  color: #ffffff;
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
}

.eva-dashboard .ellipse {
  height: 91px;
  object-fit: cover;
  width: 91px;
  border-radius: 50%;
}